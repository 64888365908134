.App {
  text-align: center;
  height: 100%;
}

.Toolbar-header {
  position: fixed;
  width: 100%;
  background-color: rgba(248, 248, 248, 0.85);
  height: 56px;
  top: 0;
  left: 0;
}

.Logo-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-width: 2px;
  border-color: #2c2c2c;
}

.Toolbar-links {
  color: #2c2c2c;
  text-decoration: none;
}

.Toolbar-links:hover {
  color: #004dff;
  text-decoration: none;
}

.Toolbar-links-active {
  color: #004dff;
  text-decoration: none;
}

.Toolbar-nav {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.Toolbar-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.Toolbar-items li {
  padding: 0 0.5rem;
}

.Spacer {
  flex: 1;
}

@media (max-width: 768px) {
  .Toolbar-items {
    display: none;
  }
}
