.Home-page {
  position: relative;
}

.Home-text-field {
  position: fixed;
  background-color: rgba(248, 248, 248, 0.85);
  height: 100%;
  width: 50%;
  top: 56px;
  left: 0;
  animation-name: Fade-in;
  animation-duration: 2s;
}

#Pool-photo {
  position: fixed;
  width: 120%;
  height: auto;
  top: 56px;
  right: 0;
  border-style: ridge;
  border-width: 2px;
  border-color: black;
  animation-name: Fade-in;
  animation-duration: 2s;
}

#Title-home {
  position: absolute;
  top: 20%;
  width: 100%;
  left: 0;
  text-align: center;
  font-size: 3.8rem;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  animation-name: Fade-in;
  animation-duration: 6s;
}

#Text-body-home-1 {
  position: absolute;
  top: 38%;
  width: 95%;
  left: 2.5%;
  text-align: center;
  font-size: 2.3rem;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  animation-name: Fade-in;
  animation-delay: 10s;
  animation-duration: 6s;
  animation-fill-mode: both;
}

#Text-body-home-2 {
  position: absolute;
  top: 14%;
  width: 75%;
  text-align: center;
  font-size: 1.75rem;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  opacity: 0;
  animation-name: Fade-in-out;
  animation-delay: 5s;
  animation-duration: 4s;
}

#Text-body-home-3 {
  position: absolute;
  top: 53%;
  width: 82%;
  right: 0;
  text-align: center;
  font-size: 2rem;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  opacity: 0;
  animation-name: Fade-in-out;
  animation-delay: 7.5s;
  animation-duration: 4s;
}

#Text-body-home-4 {
  position: absolute;
  top: 68%;
  width: 88%;
  text-align: center;
  font-size: 1.65rem;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  opacity: 0;
  animation-name: Fade-in-out;
  animation-delay: 2.5s;
  animation-duration: 4s;
}

.Home-icons {
  width: 40px;
  height: 40px;
  padding: 0 1rem;
  animation-name: Fade-in;
  animation-delay: 12.5s;
  animation-duration: 4s;
  animation-fill-mode: both;
}

.Home-icons:hover {
  cursor: pointer;
  width: 43px;
  height: 43px;
}

#Resume-button {
  width: 32px;
  height: 30px;
  font-size: 22px;
  font-weight: bold;
  background-color: transparent;
  border-style: solid;
  border-width: 3px;
  border-color: black;
  border-radius: 50%;
  color: black;
  text-align: center;
  text-decoration: none;
  margin-left: 2.5%;
  padding-top: 5px;
  padding-left: 2px;
  padding-right: 2px;
  animation-name: Fade-in;
  animation-delay: 12.5s;
  animation-duration: 4s;
  animation-fill-mode: both;
}

#Resume-button:hover {
  width: 34px;
  height: 31px;
  padding-top: 6px;
}

#Text-body-home-5 {
  display: flex;
  position: fixed;
  justify-content: center;
  top: 78%;
  width: 50%;
  font-size: 3.5rem;
}
