.Toggle-list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 25px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.Toggle-list:focus {
  outline: none;
}

.Toggle-items {
  width: 30px;
  height: 2px;
  background: black;
  margin-right: 25px;
}

.Dropdown {
  height: 182px;
  padding-top: 15px;
  background-color: #f8f8f8;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  width: 125px;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.4s ease-out;
  margin-top: 54px;
}

.Dropdown.Open {
  transform: translateX(0);
}

.Dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Dropdown li {
  padding: 0.35rem;
}

.Dropdown-links {
  color: #2c2c2c;
  text-decoration: none;
  font-size: 1.1rem;
}

.Dropdown-links :hover {
  color: #004dff;
  text-decoration: none;
  font-size: 1.1rem;
}

.Dropdown-links-active {
  color: #004dff;
  text-decoration: none;
  font-size: 1.1rem;
}

.Background {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  z-index: 100;
  top: 0;
  right: 0;
}

@media (min-width: 769px) {
  .Toggle-list {
    display: none;
  }
}
