#Linkedin-button {
  position: fixed;
  width: 55%;
  height: 50%;
  top: 56px;
  right: -55%;
  display:table-cell;
  vertical-align:middle;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
  border: none;
  cursor: pointer;
  background-color: #0077b5;
  transition: width 1s;
  transition-timing-function: ease-in-out;
  animation-name: Slide-right;
  animation-delay: 1s;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

#Linkedin-button:hover {
  width: 65%;
}

#Linkedin-button:focus {
  outline: none;
}

#Linked {
  vertical-align: text-top;
  text-align: center;
  margin: 0;
  font-size: 90vw;
  font-size: 45vh;
  color: #ffffff;
}

#Github-button {
  position: fixed;
  top: 54%;
  width: 55%;
  height: 50%;
  right: -55%;
  display: table-cell;
  vertical-align: top;
  clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  background: black;
  border: none;
  transition: width 1s;
  transition-timing-function: ease-in-out;
  animation-name: Slide-right;
  animation-delay: 1.5s;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

#Github-button:hover {
  width: 65%;
}

#Github-button:focus {
  outline: none;
}

#Github {
  vertical-align: text-top;
  text-align: center;
  margin: 0;
  width: 200px;
  height: 200px;
}

#Iceland-photo {
  position: fixed;
  width: 55%;
  height: auto;
  top: 56px;
  left: 0;
  border-style: ridge;
  border-width: 2px;
  border-color: black;
  animation-name: Fade-in;
  animation-duration: 2s;
}

.Chevon-one {
  position: fixed;
  left: -55%;
  top: 56px;
  width: 27%;
  height: 100%;
  clip-path: polygon(20% 0, 59% 46%, 15% 100%, 0% 100%, 37% 46%, 0% 0%);
  background: rgba(245, 245, 245);
  animation-name: Slide-left-1;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

.Chevon-two {
  position: fixed;
  left: -55%;
  top: 56px;
  width: 27%;
  height: 100%;
  clip-path: polygon(20% 0, 59% 46%, 15% 100%, 0% 100%, 37% 46%, 0% 0%);
  background: rgba(245, 245, 245, 0.9);
  animation-name: Slide-left-2;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

.Chevon-three {
  position: fixed;
  left: -55%;
  top: 56px;
  width: 27%;
  height: 100%;
  clip-path: polygon(20% 0, 59% 46%, 15% 100%, 0% 100%, 37% 46%, 0% 0%);
  background: rgba(245, 245, 245, 0.75);
  animation-name: Slide-left-3;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

#Text-box-div {
  position: fixed;
  width: 40%;
  height: 100%;
  background: rgba(245, 245, 245, 0.6);
  z-index: 100;
  top: 56px;
  left: -55%;
  clip-path: polygon(0% 0%, 75% 0, 98% 46%, 75% 100%, 0% 100%);
  color: black;
  animation-name: Slide-left-4;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

.Text-line-1 {
  position: relative;
  float: left;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  text-align: center;
  padding-top: 40%;
  margin-left: 7%;
  width: 75%;
  font-size: 35px;
}

#Text-line-2 {
  position: relative;
  float: left;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  color: black;
  text-align: center;
  padding-top: 7%;
  margin-left: 7%;
  width: 75%;
  font-size: 55px;
  text-decoration: none;
}

#Text-line-2:hover, #Text-line-2:active {
  color: #004dff;
  font-size: 58px;
  transition: width 1.5s;
  transition-timing-function: ease-in-out;
  animation-name: Swing;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

p span {
  position: absolute;
  width: 5%;
  animation-name: Typing;
  animation-duration: 3s;
  animation-timing-function: steps(16s);
  animation-fill-mode: forwards;
  animation-name: Blinking;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

#Text-line {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  animation-name: Blink;
  animation-duration: 1s infinite;
}

@media (max-width: 321px) {
  #Linkedin-button {
    clip-path: polygon(3% 0, 100% 0%, 100% 95%, 20% 95%);
  }

  #Linked {
    font-size: 17vh;
  }

  #Github-button {
    clip-path: polygon(28% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  #Text-box-div {
    width: 57%;
    clip-path: polygon(0% 0%, 81% 0, 98% 50%, 71% 100%, 0% 100%);
  }

  .Chevon-one {
    display: none;
  }

  .Chevon-two {
    left: 58%;
    width: 40%;
    clip-path: polygon(29% 0, 53% 50%, 16% 100%, -3% 100%, 37% 50%, 13% 0%);
  }

  .Chevon-three {
    left: 42.5%;
    width: 40%;
    clip-path: polygon(29% 0, 53% 50%, 16% 100%, -3% 100%, 37% 50%, 13% 0%);
  }
}

@media (max-width: 415px) {
  #Linkedin-button {
    clip-path: polygon(3% 0, 100% 0%, 100% 95%, 20% 95%);
  }

  #Linked {
    font-size: 17vh;
  }

  #Github-button {
    clip-path: polygon(28% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  #Text-box-div {
    width: 57%;
    clip-path: polygon(0% 0%, 81% 0, 98% 47%, 71% 100%, 0% 100%);
  }

  .Chevon-one {
    display: none;
  }

  .Chevon-two {
    left: 58%;
    width: 40%;
    clip-path: polygon(29% 0, 53% 47%, 16% 100%, -3% 100%, 37% 47%, 13% 0%);
  }

  .Chevon-three {
    left: 42.5%;
    width: 40%;
    clip-path: polygon(29% 0, 53% 47%, 16% 100%, -3% 100%, 37% 47%, 13% 0%);
  }
}

@media (max-width: 1024px) {
  #Linkedin-button {
    clip-path: polygon(3% 0, 100% 0%, 100% 100%, 30% 100%);
  }

  #Github-button {
    clip-path: polygon(30% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  #Text-box-div {
    width: 52%;
    clip-path: polygon(0% 0%, 78% 0, 100% 47%, 70% 100%, 0% 100%);
  }


  .Chevon-one {
    display: none;
  }

  .Chevon-two {
    left: 47%;
    width: 42%;
    clip-path: polygon(29% 0, 53% 47%, 22% 100%, 6% 100%, 37% 47%, 13% 0%);
  }

  .Chevon-three {
    left: 38%;
    width: 42%;
    clip-path: polygon(29% 0, 53% 47%, 22% 100%, 6% 100%, 37% 47%, 13% 0%);
  }
}

@keyframes Fade-in {
  0% {
    opacity: 0;
  }

  45% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes Fade-in-out {
  0% {
    opacity: 0;
  }

  45% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes Slide-right {
  100% {
    right: 0;
  }
}

@keyframes Slide-left-1 {
  100% {
    left: 45.5%;
  }
}

@keyframes Slide-left-2 {
  100% {
    left: 38.5%;
  }
}

@keyframes Slide-left-3 {
  100% {
    left: 31.5%;
  }
}

@keyframes Slide-left-4 {
  100% {
    left: 0;
  }
}

@keyframes Swing
{
  15%
  {
    transform: translateY(4.5%);
  }
  30%
  {
    transform: translateY(-4.5%);
  }
  50%
  {
    transform: translateY(3%);
  }
  65%
  {
    transform: translateY(-3%);
  }
  80%
  {
    transform: translateY(1.5%);
  }
  100%
  {
    transform: translateY(0);
  }
}

@keyframes Typing {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

@keyframes Blinking {
  50% {
    border-color: white;
  }
}
