.Text-about-me {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  width: 65%;
  text-align: center;
  margin-left: 30px;
  text-decoration: none;
  font-size: 20px;
}

#Line-1 {
  animation-name: Fade-in;
  animation-duration: 3.5s;
  animation-delay: 4s;
  animation-fill-mode: both;
}
#Line-2 {
  animation-name: Fade-in;
  animation-duration: 3.5s;
  animation-delay: 6.5s;
  animation-fill-mode: both;
}

#Line-3 {
  animation-name: Fade-in;
  animation-duration: 3.5s;
  animation-delay: 9s;
  animation-fill-mode: both;
}

#Line-4 {
  animation-name: Fade-in;
  animation-duration: 3.5s;
  animation-delay: 11s;
  animation-fill-mode: both;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-size: 20px;
  text-align: center;
  margin-right: 23%;
  padding-top: 15%;
}

#Text-about-resume {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-size: 18px;
  width: 65%;
  margin-right: 23%;
  text-align: center;
  text-decoration: none;
  animation-name: Fade-in;
  animation-duration: 3.5s;
  animation-delay: 11s;
  animation-fill-mode: both;
}

#Text-about-resume:visited {
  color: black;
}

#Text-about-resume:hover, #Text-about-resume:active {
  font-size: 23px;
  transition: width 1.5s;
  transition-timing-function: ease-in-out;
  color: #004dff;
}

#Text-line {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  animation-name: Blink;
  animation-duration: 1s infinite;
}

#Baby-photo {
  position: fixed;
  top: 56px;
  right: 0;
  width: 50%;
  height: auto;
}

#Lewiston-photo {
  position: fixed;
  top: 56px;
  left: 0;
  width: 50%;
  height: auto;
}

#Rafa-photo {
  position: fixed;
  top: 50%;
  left: 0;
  width: 50%;
  height: auto;
}

#Space-photo {
  position: fixed;
  top: 50%;
  right: 0;
  width: 50%;
  height: auto;
}

@keyframes Fade-in {
  0% {
    opacity: 0;
  }

  45% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
